header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    justify-content: center;
    background: #fdbcbc;
}

.navbar {
    display: flex;
    padding: 0 10px;
    max-width: 1200px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

#menuToggle {
    display: none;
}

.all-links {
    display: flex;
    align-items: center;
}

.navbar .all-links li {
    position: relative;
    list-style: none;
    right: 150px;
}

.navbar .all-links li a {
    margin: 0 50px;
}

.navbar .logo a {
    display: flex;
    align-items: center;
}

.navbar .logo img {
    width: 150px;
    height: 100px;
    object-fit: cover;
}

header a {
    margin-left: 20px;
    text-decoration: none;
    color: #fff;
    height: 100%;
    padding: 5px 0;
    font-size: 18px;
    display: inline-block;
}

header a:hover {
    color: #271d1d;
}