.contact-container {
  padding: 20px;
}

.paper-container {
  padding: 20px;
  margin-top: 120px;
}

.contact-details {
  padding: 20px;
}

.icon-contact {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-contact i {
  font-size: 24px;
  color: #007bff;
  margin-right: 10px;
}

.text-contact p {
  margin: 0;
  line-height: 1.6;
  color: #555;
}

.lineimage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.lineimage img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.lineimage p {
  padding: 15px;
  text-align: center;
  font-size: 18px;
}

.lineimage img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .paper-container {
    margin-bottom: -120px;
  }
}