@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Noto Sans Thai', sans-serif;
}

body {
  background: #f2f2f2;
}

/*Home*/
.homepage {
  top: 125px;
  height: 60vh;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}

.homepage .content {
  display: flex;
  height: 50%;
  z-index: 3;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.homepage .content .text {
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.homepage .content .text h1 {
  font-size: clamp(16px, 5vw, 150px);
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
}

.homepage .content .text2 h1 {
  font-size: clamp(16px, 4vw, 150px);
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.homepage .content .text3 p {
  margin-top: 10px;
  font-size: clamp(16px, 3.5vw, 150px);
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: auto;
  width: 100%;
}

.footer p {
  margin: auto;
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 20;
}

.icon-style {
  background-color: #f8a0a0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  border: 3px solid #333;
  background: #f8a0a0;
  color: #333;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1080px) {
  .navbar .all-links {
    position: fixed;
    left: -100%;
    width: 200px;
    display: block;
  }

  #menuToggle {
    display: block;
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }

  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #ffffff;
  }

  #menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked~span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menu {
    position: absolute;
    width: 250px;
    height: 100vh;
    margin: 0;
    padding: 20px;
    top: 70px;
    right: -50px;
    background: #333;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(120%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 20px 0;
    font-size: 16px;
  }

  #menuToggle input:checked~ul {
    transform: none;
    opacity: 1;
  }

  .navbar .logo a img {
    width: 150px;
    height: 100px;
  }

  .content a {
    font-size: 14px;
    padding: 8px 15px;
  }

  .contact .row {
    flex-direction: column;
  }

  .contact .row .col {
    width: 100%;
  }

  .contact .row .col:last-child {
    margin-top: 20px;
  }
}