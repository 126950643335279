/* Aboutus.css */
.about-us {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line on smaller screens */
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 50px;
}

.image-container1 {
  flex-basis: 40%;
  padding-top: 8rem;
  padding-left: 5rem;
}

.content1 {
  flex-basis: 60%;
  padding: 0 5rem;
  /* Adjusted padding for better responsiveness */
}

.content1 p {
  font-size: 16px;
}

.content1 h2 {
  margin-top: 15px;
}

.image-container1 img {
  max-width: 100%;
  height: auto;
  cursor: zoom-in;
}

@media (max-width: 1205px) {
  .about-us {
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  .image-container1,
  .content1 {
    flex-basis: 100%;
    margin-bottom: 2rem;
    padding: 0;
    margin: 0;
    margin-top: 2rem;

  }

  .image-container1 {
    max-width: 50%;
    padding: 0;
    margin: 0;
    height: auto;
  }
}

/* Adjustments for even smaller screens, such as mobile devices */
@media (max-width: 767px) {

  .image-container1,
  .content1 {
    max-width: 100%;
    /* Full width for smaller screens */
  }

  .content1 {
    padding: 0 2rem;
    /* Adjusted padding for better responsiveness */
  }
}