* {
    box-sizing: border-box;
}

section {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 150px 0 0;
}

section h2 {
    font-size: 1.5rem;
}

section>p {
    text-align: center;
}

section .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Center the cards horizontally */
    max-width: 100%;
    /* Adjusted for better responsiveness */
    margin-top: 50px;
    padding: 0 10px;
}

section .cards .card {
    background: #fff;
    padding: 20px 15px 15px;
    list-style: none;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
    margin: 0 6px 10px;
    /* Adjusted margin for better spacing */
    width: calc(100% / 4 - 15px);
    /* Adjusted width for better spacing */
    text-align: center;
    height: 390px;
}

.card img {
    cursor: zoom-in;
}

.cards .card img {
    width: 100%;
    height: 100%;
    /* Maintain aspect ratio */
}

.button {
    background-color: #f8a0a0;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 20px;
    cursor: pointer;
}

.button:hover {
    background-color: #eb6060;
    color: white;
}

@media screen and (max-width: 1280px) {
    section .cards .card {
        width: calc(100% / 2 - 15px);
        margin-bottom: 10px;
        height: 450px;
    }
}

@media screen and (max-width: 880px) {
    section .cards .card {
        width: calc(100% / 2 - 15px);
        margin-bottom: 10px;
        height: 400px;
    }
}

@media screen and (max-width: 670px) {
    section .cards .card {
        width: calc(100% / 2 - 15px);
        margin-bottom: 10px;
        height: 350px;
    }
}

@media screen and (max-width: 480px) {
    section .cards .card {
        width: calc(100% / 2 - 15px);
        margin-bottom: 10px;
        height: 250px;
    }
}

@media screen and (max-width: 400px) {
    section .cards .card {
        width: calc(100% / 2 - 15px);
        margin-bottom: 10px;
        height: 200px;
    }
}